import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
import Routes from './routes/MainRoutes';
import preloadImage from "../src/assets/images/topImage1.webp"
import './App.css';
import FallbackLoader from './Components/Common/loader/fallBackLoader';

function App() {
  const LanguageReducer = useSelector((state) => state.LanguageReducer);

  useEffect(() => {
    document.body.classList.add(LanguageReducer.direction);
    return () => {
      document.body.classList.remove(LanguageReducer.direction);
    };
  }, [LanguageReducer.direction]);

  
  return (
    <div className={'App'}>
      <Helmet>
        <title>Shipra - The World's Leading Logistics 3PL Software Provider for E-commerce</title>
        <meta name="description" content="Shipra is the world's leading logistics 3PL software provider for e-commerce, offering comprehensive solutions for your shipping needs." />
        <meta property="og:title" content="Shipra - The World's Leading Logistics 3PL Software Provider for E-commerce" />
        <meta property="og:site_name" content="Shipra" />
        <meta property="og:url" content="https://www.shipra.io" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Shipra is the world's leading logistics 3PL software provider for e-commerce." />
        <link rel="preload" href={preloadImage} as="image" />
      </Helmet>
      <BrowserRouter>
      <Suspense fallback={<FallbackLoader />}>
          <Routes />
        </Suspense>
      </BrowserRouter>
      <iframe style={{border: "0",position: "fixed", bottom: "0%", marginBottom:"70px", right:"0"}} width="250" height="50" src="https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=teal&size=standard&radius=4px&label=Messageus on WhatsApp&number=15557047570"></iframe>
    </div>
  );
}

export default App;
